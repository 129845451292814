<template>
  <component :is="layout">
    <prompt :active="isModalVisible" @close="isModalVisible = false">
      <h3 slot="header">
        Set automation title
      </h3>
      <div slot="content">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <div class="form-group">
              <label for="title">Enter title</label>
              <input
                v-if="automation"
                id="title"
                v-model="automation.name"
                type="text"
                class="form-control"
              />
              <small id="title-description" class="form-text text-muted"
                >Give your automation a title</small
              >
            </div>
            <button class="btn btn-primary btn-lg btn-block" @click="submit">
              Save automation
            </button>
          </div>
        </div>
      </div>
    </prompt>

    <prompt
      :active="isRerunVisible"
      :title="'Rerun automation'"
      @close="isRerunVisible = false"
    >
      <div slot="content">
        <rerun-automation
          v-if="isRerunVisible"
          :automation-id="id"
        />
      </div>
    </prompt>
    <div v-if="!showSaved">
      <Automation
        v-if="automation"
        :automation="automation"
        :loading="loading"
        @save="save"
      />
    </div>
    <div v-else class="col-12 mt-6">
      <div class="row fadeInUp">
        <div class="col-12 col-md-6 offset-md-3">
          <div class="card border-0 shadow-sm fadeInUp">
            <div class="card-header border-bottom">
              <p class="h4 mb-0">
                Automation created
              </p>
              <p class="h5 text-muted mb-0">
                You're automation is ready
              </p>
            </div>
            <div class="card-body">
              <outline :automation="automation" />
            </div>
            <div class="card-footer">
              <button class="btn btn-outline-primary" @click="editAutomation">
                Edit automation
              </button>
              <button
                v-if="!active"
                class="btn btn-success float-right"
                @click="setLive"
              >
                <span
                  v-if="activating"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                Set live
              </button>
              <div v-else class="btn btn-success float-right">
                Your automation is now active
              </div>
            </div>
          </div>
          <div 
            class="card border-0 shadow-sm fadeInUp mt-5"
            v-if="showRerun"
          >
            <div class="card-body">
              <router-link
                :to="{
                  name: 'automations.index'
                }" 
              >
                <button class="btn btn-outline-primary">
                  Go to automations
                </button>
              </router-link>
              <button
                type="submit"
                class="btn btn-primary float-right"
                @click="rerunAutomation"
              >
                Rerun automation
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import confetti from "canvas-confetti";
import jsonAPI from "@/api";
// Flow split
import Automation from "@/components/Automation/Automation";
import Default from "@/layouts/default.vue";
import Outline from "@/components/Automation/Outline";
import Prompt from "@/components/UI/Prompt";
import RerunAutomation from "@/components/Automation/RerunAutomation.vue";
// Helpers.
import { postToApi, updateApi } from "@/helpers/apiConnection";
// Setup.
var myCanvas = document.getElementById("canvas");
var myConfetti = confetti.create(myCanvas, { resize: true });

export default {
  components: {
    Automation,
    Default,
    Outline,
    Prompt,
    RerunAutomation,
  },
  beforeRouteEnter(to, from, next) {
    const _id = to.params.id;
    jsonAPI.get("automationRule/" + _id).then((response) => {
      next((vm) => {
        vm.automation = response.data;
        vm.isLoading = false;
      });
    });
  },
  data() {
    return {
      automation: null,
      loading: false,
      isLoading: true,
      showSaved: false,
      isModalVisible: false,
      activating: false,
      active: false,
      layout: "Default",
      showRerun: false,
      isRerunVisible: false,
    };
  },
  methods: {
    save(automation) {
      this.loading = true;
      delete automation.id;
      delete automation.automationAction.id;
      this.automation = automation;
      this.isModalVisible = true;
    },
    async submit() {
      const res = await postToApi("/automationRule", this.automation).catch(
        (error) => {
          window.Bus.$emit("flash-message", {
            text: error.message,
            type: "error",
          });
        }
      );
      if (res) {
        this.isModalVisible = false;
        this.id = res.id;
        this.automationCreatedMsg();
      }
      this.loading = false;
    },
    automationCreatedMsg() {
      this.showSaved = true;
      setTimeout(function() {
        var end = Date.now() + 2000;

        var interval = setInterval(function() {
          if (Date.now() > end) {
            return clearInterval(interval);
          }

          myConfetti({
            startVelocity: 30,
            spread: 360,
            ticks: 60,
            origin: {
              x: Math.random(),
              y: Math.random() - 0.2,
            },
          });
        }, 200);
      }, 500);
    },
    async setLive() {
      await updateApi(`/automationRule/status/${this.id}`, {
        active: true,
      }).catch(() => {
        this.loading = false;
        return;
      });
      this.activating = true;
      setTimeout(() => {
        this.active = true;
        this.activating = false;
        this.showRerun = true;
      }, 2000);
    },
    rerunAutomation() {
      this.isRerunVisible = true;
    },
    editAutomation() {
      this.$router.push({ name: "automations.edit", params: { id: this.id } });
    },
  },
};
</script>
